@import "mixins";

.ui-widget {
  font-family: inherit;
}
.ui-dialog {
  position: absolute;
  padding: .2em;
  width: 100%;
  margin: 0 20px;
  overflow: hidden;

    @media all and (min-width: 620px) {
    width: 600px !important;
  }
   }
.ui-dialog .ui-dialog-titlebar {
  padding: .5em 1em .3em;
  position: relative;
  background-image: none;
    background-color: transparent;
    border: none;
   }
.ui-dialog .ui-dialog-title { float: left; margin: .1em 16px .2em 0; }
.ui-dialog .ui-dialog-titlebar-close { position: absolute; right: .3em; top: 50%; width: 19px; margin: -10px 0 0 0; padding: 1px; height: 18px; }
.ui-dialog .ui-dialog-titlebar-close span { display: block; margin: 1px; }
.ui-dialog .ui-dialog-titlebar-close:hover, .ui-dialog .ui-dialog-titlebar-close:focus { padding: 0; }
.ui-dialog .ui-dialog-content {
  position: relative;
  border: 0;
  padding: .5em 1em;
  font-size: 13px;
  background: none;
  overflow: auto;
  zoom: 1;
}
.ui-dialog .ui-dialog-buttonpane {
  text-align: left; border-width: 1px 0 0 0; background-image: none; border: none; margin: .5em 0 0 0; padding: 0 1em .5em .4em; }
.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset { float: none; text-align: center; }
.ui-dialog .ui-dialog-buttonpane button {
  cursor: pointer;
   @include standard-button;
   @include standard-button-hover;
    margin: 1em 1em .5em 0 !important;
   background-image: none;
   width: auto;
  cursor: pointer;
  border: none;
  border-radius: 0;
  padding: 0.65em 1em;
  background-color: #24ace0;
  color: #fff;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 0.8461em;
  font-family: Helvetica, Arial, sans-serif;

     &:last-child {
    background-color: #8DC63F;

     &:hover {
      opacity: 0.95;
     }
   }
}
.ui-dialog .ui-dialog-titlebar-close {
  border: none;
  background-color: transparent;
  background-image: none;
}
.ui-button-icon-only .ui-icon {
  left: 0;
  top: 0;
}
.ui-button-text-only .ui-button-text {
  padding: 0;
}
.ui-dialog .ui-resizable-se { width: 14px; height: 14px; right: 3px; bottom: 3px; }
.ui-draggable .ui-dialog-titlebar { cursor: move; }
